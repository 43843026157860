@use 'sass:color';

@import 'normalize';
@import 'color';
@import 'type';

:root {
  font-family: 'InterDisplay', sans-serif;
  font-feature-settings: 'liga' 1, 'calt' 1;
}

.light {
  body {
    background: $color-dr06-buff;
  }
  h1 {
    color: color.scale($color-dr06-buff, $lightness: -5%);    
  }
}
.dark {
  body {
    background: $color-dr05-blue-base;
  }
  h1 {
    color: color.scale($color-dr05-blue-base, $lightness: 5%);
  }
}
body {
  align-items: center;
  display: flex;
  height: 100vh;
  justify-content: center;
  overflow: hidden;
}
h1 {
  font-size: 14.4vw;
  font-style: italic;
  font-weight: 900;
  margin: 0;
}